// TODO: Mapping specific errors
export const Errors = {
  INVALID_SELECTED_ADDRESS: 'INVALID_SELECTED_ADDRESS',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  APPROVAL_REQUEST_FAILED : 'Network request failed. Please try again.',
}


export enum ErrorMessage {
  LEDGER_APP_NOT_OPEN = 'Please make sure the app is open on your ledger device.',
  LEDGER_WRONG_APP = 'Please make sure that the app you have opened on your ledger device is the correct one.',
  LEDGER_TX_REJECTED = 'Transaction rejected by the user on the ledger device.',
  LEDGER_LOCKED = 'Make sure your ledger device is unlocked.',
  LEDGER_ETH_ENABLE_BLIND_SIGNING = 'Please enable the blind signing config on the Ethereum app on your Ledger device.',
  LEDGER_SOL_ENABLE_BLIND_SIGNING = 'Please enable the blind signing config on the Solana app on your Ledger device.',
  LEDGER_NOT_CONNECTED = 'Ledger not connected',
  LEDGER_DEVICE_DISCONNECTED = 'Ledger device was disconnected',
  INSUFFICIENT_FUNDS = 'Verify that you have sufficient funds to execute this transaction.',
  USER_REJECTED_TX = 'Transaction rejected',
  NOT_CONNECT = 'Wallet not connected',
  INVALID_LINK = 'Please access the application with a valid link',
  INVALID_PAYMENT_ID = 'Please access the application with a valid payment id',
  IGNORE_MAPPING = 'IGNORE_MAPPING',
  GENERIC_PAYMENT_ERROR = 'Something went wrong with your payment. Please try again.',
  USER_DATA_ERROR = 'Error while fetching user data',
  SWAP_DATA_ERROR = 'Error while fetching swap data',
  WALLET_ERROR = 'Wallet error',
  WALLET_CONNECTION_REJECTED = 'Wallet connection rejected',
}
export enum PostOperationType {
  SIGN_TRANSACTION = 'signTransaction',
  WALLET_CONNECT = 'walletConnection',
  DELEGATE_FUNDS = 'delegateFunds',
  WALLET_BALANCES = 'walletBalances',
  ABORT = 'abort',
  CLOSE = 'close',
  EVENT = 'event',
}

export enum PostErrorMessage {
  SIGNATURE_ERROR = 'SIGNATURE_ERROR',
  DELEGATE_FUNDS = 'DELEGATE_FUNDS',
  WALLET_CONNECTION_ERROR = 'WALLET_CONNECTION_ERROR',
  WALLET_NOT_CONNECTED = 'WALLET_NOT_CONNECTED',
  USER_DATA = 'USER_DATA',
  SWAP_DATA = 'SWAP_DATA',
  WALLET_NO_NETWORK = 'NO_NETWORK_PROVIDED',
  WALLET_INCOMPATIBLE_NETWORK = 'INCOMPATIBLE_NETWORK'
}

const PostMessageMap: {
    [key in PostErrorMessage]: {
        type: PostErrorMessage
        title: string
    }
} = {
    [PostErrorMessage.SIGNATURE_ERROR]: {
        type: PostErrorMessage.SIGNATURE_ERROR,
        title: 'The message was not signed',
    },
    [PostErrorMessage.DELEGATE_FUNDS]: {
        type: PostErrorMessage.DELEGATE_FUNDS,
        title: 'The funds could not be delegated',
    },
    [PostErrorMessage.WALLET_CONNECTION_ERROR]: {
        type: PostErrorMessage.WALLET_CONNECTION_ERROR,
        title: 'Wallet connect error',
    },
    [PostErrorMessage.WALLET_NOT_CONNECTED]: {
        type: PostErrorMessage.WALLET_NOT_CONNECTED,
        title: 'Wallet connection error',
    },
    [PostErrorMessage.USER_DATA]: {
        type: PostErrorMessage.USER_DATA,
        title: 'Error while retrieving user data',
    },
    [PostErrorMessage.SWAP_DATA]: {
        type: PostErrorMessage.SWAP_DATA,
        title: 'Error retrieving swap data',
    },
    [PostErrorMessage.WALLET_NO_NETWORK]: {
        type: PostErrorMessage.WALLET_NO_NETWORK,
        title: 'No network provided',
    },
    [PostErrorMessage.WALLET_INCOMPATIBLE_NETWORK]: {
        type: PostErrorMessage.WALLET_INCOMPATIBLE_NETWORK,
        title: 'Incompatible network',
    },
}

interface PostErrorResponse {
  type: PostOperationType
  data: {
    error: {
      type: PostErrorMessage
      title: string
      details?: string
    }
  }
}

const LEDGER_MESSAGE_BY_ERROR_CODE = {
  27904: ErrorMessage.LEDGER_WRONG_APP,
  27906: ErrorMessage.LEDGER_APP_NOT_OPEN,
  27267: ErrorMessage.LEDGER_APP_NOT_OPEN,
  25873: ErrorMessage.LEDGER_APP_NOT_OPEN,
  27013: ErrorMessage.LEDGER_TX_REJECTED
}

const ERROR_MESSAGE_MAPPING = {
  'insufficient funds': ErrorMessage.INSUFFICIENT_FUNDS,
  'user rejected': ErrorMessage.USER_REJECTED_TX,
  'user denied transaction signature': ErrorMessage.USER_REJECTED_TX,
  'locked device': ErrorMessage.LEDGER_LOCKED,
  'account does not support': ErrorMessage.IGNORE_MAPPING,
  'ledger device': ErrorMessage.LEDGER_APP_NOT_OPEN,
  usbdevice: ErrorMessage.LEDGER_NOT_CONNECTED,
  'not connected': ErrorMessage.NOT_CONNECT,
  'blind signing': ErrorMessage.LEDGER_ETH_ENABLE_BLIND_SIGNING,
  'blind signature': ErrorMessage.LEDGER_SOL_ENABLE_BLIND_SIGNING,
  'invalid token': ErrorMessage.INVALID_LINK,
  requestdevice: ErrorMessage.LEDGER_DEVICE_DISCONNECTED
}

const ERROR_KEYS = Object.keys(ERROR_MESSAGE_MAPPING)

const getMappedError = (error: string) => {
  // Opera wallet returns an undefined object when there is not funds
  if (!error) return ErrorMessage.GENERIC_PAYMENT_ERROR
  const retrievedError = ERROR_KEYS.find((key) =>
    error.toLocaleLowerCase().includes(key)
  )
  return retrievedError && ERROR_MESSAGE_MAPPING[retrievedError] !== ErrorMessage.IGNORE_MAPPING
    ? ERROR_MESSAGE_MAPPING[retrievedError]
    : error
}

export const getErrorMessage = (error: any) => {
  // ledger handler
  if (error.statusCode) return LEDGER_MESSAGE_BY_ERROR_CODE[error.statusCode]

  if (error.message) {
    return getMappedError(error.message)
  }
  return JSON.stringify(error)
}

// As the try/catch error in typescript is always any or unknown, we receive an any
export const handleError = (error: any) => {
  const isError = error instanceof Error
  // ledger error
  if (error.statusCode) {
    if (!LEDGER_MESSAGE_BY_ERROR_CODE[error.statusCode]) {
      console.error('Ledger error: ', error)
      throw error
    }
    return
  }
  const errorMessage =
    isError ? error.message : JSON.stringify(error)

  const retrievedError = ERROR_KEYS.find((key) =>
    errorMessage.toLocaleLowerCase().includes(key)
  )

  if (!retrievedError) {
    console.error('Error: ', error)
    isError && console.error('Stack: ', error.stack)
    throw error
  }
}

export const getPostMessageError = (operationType: PostOperationType, errorType: PostErrorMessage, error?: { message: string, reason: string }): PostErrorResponse => {
  const errorMessage = error && (error.reason || error.message || JSON.stringify(error))
  return {
    type: operationType,
    data: {
      error: {
        ...PostMessageMap[errorType],
        details: errorMessage
      }
    }
  }
}
